import React, { Component } from 'react';
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";

// Component
import ViewportRestrict from 'component/viewport';
import Loader from 'component/loader';

// Pages
// import Dashboard from 'pages/Dashboard';
import Login from 'pages/Login';
import KPI from 'pages/KPI';
import Ticket from 'pages/Tickets';
import BulkReply from 'pages/Tickets/BulkReply';
import ErrorBoundary from 'ErrorBoundary';
import Header from 'partial/Header';
import Navbar from 'partial/Navbar';

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from 'redux/actions/main';
import * as ticketActions from 'redux/actions/ticket';


const NoMatch = () => (
	<div>404 Not Found</div>
);

class Main extends Component {

	componentDidMount() {
		const { actions } = this.props;
		actions.set_token();
		actions.get_categories();
	}

	render() {
		const { main, actionsTicket, actions } = this.props;

		return (
			<Router basename={process.env.REACT_APP_BASENAME}>
				<div id="Main" className="main-panel">
					<ViewportRestrict display={false} type="landscape" />
					<Loader loader={main.loader} />
					<Header data={main.user} get_ticket={actionsTicket.get_ticket} change_nav={actions.put_nav} />
					<Navbar active={main.nav} change_nav={actions.put_nav} />
					<div className="container main-panel mt-3">
						<ErrorBoundary>
							<Switch>
								<Route exact path="/" component={Login} />
								{/* <Route exact path="/dashboard" component={Dashboard} /> */}
								<Route exact path="/kpi" component={KPI} />
								<Route exact path="/ticket/:type(all|new|pending|new_replies|progress|closed|trash|resolved|unresolved|overdue)" component={Ticket} />
								<Route exact path="/ticket/bulk_reply" component={BulkReply} />
								<Route component={NoMatch} />
							</Switch>
						</ErrorBoundary>
					</div>
				</div>
			</Router>
		);
	}
}

function mapStateToProps(state) {
	return { main: state.main }
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch),
		actionsTicket: bindActionCreators(ticketActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);