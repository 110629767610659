import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class TopNav extends Component {

    componentDidMount() {
        const { type } = this.props;
        let el = document.querySelector(`#${type}`)
        let other_el = document.querySelectorAll('a.nav-link');
        other_el.forEach(el => {
            el.classList.remove('active')
        });
        el.classList.add('active')
    }

    handleClick = (e, type) => {
        const { handleTabChange } = this.props;
        handleTabChange(type)
        let other_el = document.querySelectorAll('a.nav-link');
        other_el.forEach(el => {
            el.classList.remove('active')
        });
        e.currentTarget.classList.add('active')
    }

    render() {
        const { total } = this.props;
        return (
            <>
                <nav id="TopNav" className="nav wd-100p">
                    <Link id="all" to="/ticket/all" onClick={(e) => this.handleClick(e, "all")} className={"nav-link px-2 tx-center active"}>All</Link>
                    <Link id="new" to="/ticket/new" onClick={(e) => this.handleClick(e, "new")} className={"nav-link px-2 tx-center"}>New</Link>
                    <Link id="pending" to="/ticket/pending" onClick={(e) => this.handleClick(e, "pending")} className={"nav-link px-2 tx-center"}>Pending</Link>
                    <Link id="new_replies" to="/ticket/new_replies" onClick={(e) => this.handleClick(e, "new_replies")} className={"nav-link px-2 tx-center"}>Replies</Link>
                    <Link id="progress" to="/ticket/progress" onClick={(e) => this.handleClick(e, "progress")} className={"nav-link px-2 tx-center"}>Progress</Link>
                    <Link id="closed" to="/ticket/closed" onClick={(e) => this.handleClick(e, "closed")} className={"nav-link px-2 tx-center"}>Closed</Link>
                    <Link id="trash" to="/ticket/trash" onClick={(e) => this.handleClick(e, "trash")} className={"nav-link px-2 tx-center"}>Trash</Link>
                    <Link id="resolved" to="/ticket/resolved" onClick={(e) => this.handleClick(e, "resolved")} className={"nav-link px-2 tx-center"}>Resolved</Link>
                    <Link id="unresolved" to="/ticket/unresolved" onClick={(e) => this.handleClick(e, "unresolved")} className={"nav-link px-2 tx-center"}>Unresolved</Link>
                    <Link id="overdue" to="/ticket/overdue" onClick={(e) => this.handleClick(e, "overdue")} className={"nav-link px-2 tx-center"}>Overdue</Link>
                </nav>
                <div className="wd-100 tx-right"> Total : <span className="tx-semibold">{total}</span> </div>
            </>
        );
    }
}

export default TopNav;