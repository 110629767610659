import axios from 'axios';
import { map_err, toggle_loader } from './main'
import { reset_state } from 'redux/reducers/ticket';

const $ = window.$;

export const put_data = (key, data) => ({
    type: "PUT_DATA",
    key,
    data
})

export const toggle_modal = () => ({
    type: "TOGGLE_MODAL"
})

export const append_chat = (data) => ({
    type: "APPEND_CHAT",
    data
})

export const map_data = (data) => ({
    type: "MAP_DATA",
    data
})

export const get_ticket = (args = false, clear = false) => {

    return (dispatch, getState) => {
        dispatch(toggle_loader(true))

        const { ticket } = getState();

        if (clear) {
            args = { ...reset_state };
        }

        if (args) {
            for (const key in ticket) {
                if (!args.hasOwnProperty(key)) {
                    args = {
                        ...args,
                        [key]: ticket[key]
                    };
                }
            }

            dispatch(map_data(args));
        }

        const { base, query, ticket_type, sort_type, sort_by, date_from, date_to, category, from, title, convo, page, status } = (args) ? args : ticket;

        const ticket_num = query;
        const ticket_num_search = ticket_num ? base === "ticket_number" ? "full" : "suffix" : "";
        const sender = from;
        const conversation = convo;

        axios.get(`otrs/ticket_list/${ticket_type}/?page=${page}&ticket_num=${ticket_num}&ticket_num_search=${ticket_num_search}&sender=${sender}&title=${title}&conversation=${conversation}&cat=${category}&date_from=${date_from}&date_to=${date_to}&sort_by=${sort_by}&sort_type=${sort_type}&status=${status}`)
            .then(resp => {
                dispatch(put_data("ticket_data", resp.data.data));
                dispatch(put_data("total_data", resp.data.total_data));
                dispatch(put_data("total_page", resp.data.total_page));
                dispatch(put_data("current_page", page));
            })
            .catch(err => {
                console.log(err.response)
            })
            .then(() => {
                if (clear) {
                    const el_name = ["base", "query", "from", "title", "convo", "category", "date_from", "date_to", "sort_by", "sort_type"];

                    el_name.forEach(dt => {
                        let el = document.querySelector(`*[name=${dt}]`)
                        for (const key in reset_state) {
                            if (dt === key) {
                                el.value = reset_state[key]
                            }
                        }
                    });

                }
                dispatch(toggle_loader(false))
            })
    }
}

export const reset_detail = () => {
    return dispatch => {
        dispatch(put_data("ticket_detail", false));
    }
}

export const get_ticket_detail = (ticket_number) => {
    return dispatch => {
        dispatch(toggle_loader(true))

        axios.get(`otrs/ticket_detail/${ticket_number}/`)
            .then(resp => {
                dispatch(put_data("ticket_detail", resp.data));
                dispatch(put_data("conversation", resp.data.ticket_conversation));
                $('#modal-ticket').modal('show');
            })
            .catch(err => {

            })
            .then(() => {
                dispatch(toggle_loader(false))
            })
    }
}

export const add_conversation = (ticket_number, data) => {
    return dispatch => {
        axios.post(`otrs/add_conversation/${ticket_number}/`, data)
            .then(resp => {
                dispatch(get_ticket())
                dispatch(get_ticket_detail(ticket_number));
                window.quill.setContents([]);
            })
            .catch(err => {
                dispatch(map_err(err));
            })
    }
}

export const move_ticket = (ticket_number, status_to) => {
    return dispatch => {
        dispatch(toggle_loader(true))

        axios.post(`otrs/move_ticket/${ticket_number}/${status_to}`)
            .then(resp => {
                dispatch(get_ticket())
            })
            .catch(err => {
                dispatch(map_err(err))
            })
            .then(() => {
                dispatch(toggle_loader(false))
            })
    }
}

export const map_state = (type) => {
    return dispatch => {
        let other_state = {
            ticket_type: "all",
            status: ""
        }

        switch (type) {
            case 'progress':
                other_state.status = 2
                break;
            case 'closed':
                other_state.status = 3
                break;
            case 'trash':
                other_state.status = 4
                break;
            case 'resolved':
                other_state.status = 6
                break;
            case 'unresolved':
                other_state.status = 7
                break;
            case 'overdue':
                other_state.status = 8
                break;
            default:
                other_state.ticket_type = type
                other_state.status = ""
                break;
        }

        return other_state
    }
}




// export const reset_state = (data) => ({
//     type: "RESET_STATE",
//     data
// })