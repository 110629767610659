import React, { Component } from "react";
import io from "socket.io-client";

import PartialModal from "partial/PartialModal";
import Timeline from "./Timeline";
import { date_parser } from "helper";
import { leave_room } from "socket";

const $ = window.$;
const Quill = window.Quill;
window.socket = io.connect(process.env.REACT_APP_SOCKET_URL, {
    transports: ["websocket"]
});

let init_state = {
    cat: "",
    subcat: "",
    subsubcat: "",
    status: "",
    populated: false
};

class DetailModal extends Component {
    state = {};

    componentDidMount() {
        let { reset_detail, ticket_detail } = this.props;

        this.setState({
            ...this.state,
            ...init_state
        });

        // $('#modal-ticket select').select2({
        //     minimumResultsForSearch: Infinity,
        //     dropdownParent: $('#status-cont-select')
        // });

        // $('#category-cont-select select').select2({
        //     minimumResultsForSearch: Infinity,
        //     dropdownParent: $('#category-cont-select')
        // })

        // $('#status-cont-select select').select2({
        //     minimumResultsForSearch: Infinity,
        //     dropdownParent: $('#status-cont-select')
        // })

        window.quill = new Quill(".text-editor", {
            theme: "snow",
            placeholder: "Type your reply here..."
        });

        $("#modal-ticket").on("hidden.bs.modal", function (e) {
            leave_room(ticket_detail.ticket_number);
            reset_detail();
        });

        window.socket.on("user_otrs_mena_response", this.appendChat);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.ticket_detail && !state.populated) {
            let new_state = {
                cat: "",
                status: "",
                subcat: "",
                subsubcat: "",
                populated: true
            };

            if (props.ticket_detail.ticket_title !== state.title) {
                new_state.title = props.ticket_detail.ticket_title;
            }

            if (props.ticket_detail.status.id !== state.status) {
                new_state.status = props.ticket_detail.status.id;
            }

            if (props.ticket_detail.category.id !== state.cat) {
                new_state.cat = props.ticket_detail.category.id;
            }

            if (props.ticket_detail.sub_category.id !== state.subcat) {
                new_state.subcat = props.ticket_detail.sub_category.id;
            }

            if (props.ticket_detail.sub_sub_category.id !== state.subsubcat) {
                new_state.subsubcat = props.ticket_detail.sub_sub_category.id;
            }

            return new_state;
        } else if (!props.ticket_detail && state.populated) {
            return init_state;
        } else {
            return null;
        }
    }

    appendChat = (msg) => {
        const { append_chat } = this.props;
        append_chat(msg);
    };

    appendChat_bk = (msg) => {
        // return
        const { ticket_detail } = this.props;
        let data = msg.data;
        if (typeof data !== "string") {
            let el = document.querySelector("#Timeline");

            let timeline_item = document.createElement("div"),
                timeline_time = document.createElement("div"),
                timeline_time_content = document.createElement("div"),
                timeline_body = document.createElement("div"),
                timeline_title = document.createElement("p"),
                timeline_title_badge = document.createElement("span"),
                timeline_title_name = document.createElement("span"),
                timeline_text = document.createElement("p");

            // APPEND ELEMENT
            timeline_item.append(timeline_time);
            timeline_item.append(timeline_body);
            timeline_time.append(timeline_time_content);
            timeline_body.append(timeline_title);
            timeline_body.append(timeline_text);
            timeline_title.append(timeline_title_badge);
            timeline_title.append(timeline_title_name);

            // ADDING CLASS
            timeline_item.classList.add("timeline-item", "timeline-day");
            timeline_time.classList.add("timeline-time");
            timeline_body.classList.add("timeline-body", "pd-r-25");
            timeline_title.classList.add(
                "timeline-title",
                "d-flex",
                "align-items-center"
            );
            timeline_title_badge.classList.add(
                "badge",
                "badge-pill",
                data.agent ? "badge-info" : "badge-warning"
            );
            timeline_title_name.classList.add("ml-1");
            timeline_text.classList.add("timeline-text", "text-justify");

            timeline_time_content.innerText = data.created_at;
            timeline_title_badge.innerText = data.agent ? "Agent" : "Client";
            timeline_title_name.innerText = data.agent
                ? data.agent.email
                : ticket_detail.user_open.nickname;

            timeline_text.innerHTML = data.content;

            el.insertBefore(timeline_item, el.childNodes[0] || null);

            el.childNodes[1].classList.remove("timeline-day");
        }
    };

    handleChangeDrop = (e) => {
        let { name, value } = e.currentTarget;

        this.setState({
            [name]: parseInt(value)
        });
    };

    handleCategory = () => {
        const { categories } = this.props;
        if (categories) {
            return categories.map((dt, idx) => (
                <option key={idx} value={dt.id}>
                    {dt.name}
                </option>
            ));
        }
        return;
    };

    handleSubCategory = () => {
        const { ticket_detail, categories } = this.props;
        if (ticket_detail) {
            let category = categories.filter((dt) => {
                return dt.id === this.state.cat;
            });

            if (category < 1) {
                return;
            }

            return category[0].sub_category.map((dt, idx) => (
                <option key={idx} value={dt.id}>
                    {dt.name}
                </option>
            ));
        }
    };

    handleSubSubCategory = () => {
        const { ticket_detail, categories } = this.props;
        if (ticket_detail) {
            let category = categories.filter((dt) => dt.id === this.state.cat);
            if (category.length < 1) {
                return;
            }

            let sub_category = category[0].sub_category.filter(
                (dt) => dt.id === this.state.subcat
            );
            if (sub_category.length < 1) {
                return;
            }

            return sub_category[0].sub_sub_category.map((dt, idx) => (
                <option key={idx} value={dt.id}>
                    {dt.name}
                </option>
            ));
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { ticket_detail, add_conversation } = this.props;
        let message = "",
            formdata = new FormData(),
            length = window.quill.getText().trim().length;

        if (length > 0) {
            message = window.quill.container.firstChild.innerHTML;
        }

        if (ticket_detail) {
            let ticket_number = ticket_detail.ticket_number,
                language = ticket_detail.lang,
                category_el = document.querySelector("#category-select"),
                subcategory_el = document.querySelector("#subcategory-select"),
                status_el = document.querySelector("#status-select"),
                subsubcategory_el = document.querySelector(
                    "#subsubcategory-select"
                );

            formdata.append("category", category_el.value);
            formdata.append("sub_category", subcategory_el.value);
            formdata.append("sub_sub_category", subsubcategory_el.value);
            formdata.append("status", status_el.value);
            formdata.append("content", message);
            formdata.append("lang", language);

            add_conversation(ticket_number, formdata);
        }
    };

    componentWillUnmount() {
        window.socket.off("user_otrs_mena_response", this.appendChat);
    }

    render() {
        const { ticket_detail, get_ticket_detail, conversation } = this.props;
        const { cat, subcat, subsubcat, status } = this.state;

        return (
            <PartialModal
                id="modal-ticket"
                title="Ticket Detail"
                bodyClass="pd-30"
                dialogClass="wd-90p"
                footer={true}
                handleSubmit={this.handleSubmit}
            >
                <div className="form-layout mb-3">
                    <div className="row no-gutters">
                        <div className="col-8 pr-1">
                            <div className="form-group">
                                <label className="form-control-label">
                                    Title:{" "}
                                </label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    placeholder="Fetching Data..."
                                    value={
                                        ticket_detail
                                            ? ticket_detail.ticket_title
                                            : ""
                                    }
                                    readOnly
                                    required
                                />
                            </div>
                        </div>
                        <div className="col-2 px-2">
                            <div className="form-group">
                                <label className="form-control-label">
                                    Ticket Number:{" "}
                                </label>
                                <input
                                    type="text"
                                    name="ticket_number"
                                    className="form-control mb-2"
                                    placeholder="Fetching Data..."
                                    defaultValue={
                                        ticket_detail
                                            ? ticket_detail.ticket_number
                                            : ""
                                    }
                                    readOnly
                                    required
                                />
                            </div>
                        </div>
                        <div className="col-2 p-0">
                            <div className="form-group bd-t-0-force">
                                <label className="form-control-label">
                                    Language
                                </label>
                                <input
                                    type="text"
                                    name="sender"
                                    className="form-control mb-2"
                                    defaultValue={
                                        ticket_detail
                                            ? ticket_detail?.lang?.toUpperCase()
                                            : ""
                                    }
                                    placeholder="Fetching Data..."
                                    readOnly
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row no-gutters">
                        <div className="col-4">
                            <div className="col-12 pr-2 pl-0">
                                <div className="form-group bd-t-0-force">
                                    <label className="form-control-label">
                                        Date Created
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-2"
                                        placeholder="Fetching Data..."
                                        defaultValue={
                                            ticket_detail
                                                ? date_parser(
                                                    ticket_detail.created_at
                                                )
                                                : ""
                                        }
                                        readOnly
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-12 pr-2 pl-0">
                                <div className="form-group bd-t-0-force">
                                    <label className="form-control-label">
                                        Last Reply Date
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-2"
                                        placeholder="Fetching Data..."
                                        defaultValue={
                                            ticket_detail
                                                ? date_parser(
                                                    ticket_detail.created_at
                                                )
                                                : ""
                                        }
                                        readOnly
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-12 pr-2 pl-0">
                                <div className="form-group bd-t-0-force">
                                    <label className="form-control-label">
                                        Closed By
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-2"
                                        placeholder="Fetching Data..."
                                        defaultValue={
                                            ticket_detail
                                                ? ticket_detail.closed_by
                                                    ? ticket_detail.closed_by
                                                    : "-"
                                                : "-"
                                        }
                                        readOnly
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="col-12 px-1">
                                <div className="form-group bd-t-0-force">
                                    <label className="form-control-label">
                                        Sender
                                    </label>
                                    <input
                                        type="text"
                                        name="sender"
                                        className="form-control mb-2"
                                        defaultValue={
                                            ticket_detail
                                                ? ticket_detail.user_open
                                                    .nickname
                                                : ""
                                        }
                                        onChange={(e) =>
                                            this.props.actions.change_sender(
                                                e.currentTarget.value
                                            )
                                        }
                                        placeholder="Fetching Data..."
                                        readOnly
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-12 px-1">
                                <div className="form-group bd-t-0-force">
                                    <label className="form-control-label">
                                        Open ID
                                    </label>
                                    <input
                                        type="text"
                                        name="open_id"
                                        className="form-control mb-2"
                                        defaultValue={
                                            ticket_detail
                                                ? ticket_detail.user_open
                                                    .open_id
                                                : ""
                                        }
                                        onChange={(e) =>
                                            this.props.actions.change_sender(
                                                e.currentTarget.value
                                            )
                                        }
                                        placeholder="Fetching Data..."
                                        readOnly
                                        required
                                    />
                                </div>
                            </div>

                            <div
                                id="status-cont-select"
                                className="col-12 px-1"
                            >
                                <div className="form-group bd-t-0-force">
                                    <label className="form-control-label">
                                        Status:
                                    </label>
                                    <select
                                        id="status-select"
                                        value={status}
                                        name="status"
                                        className="form-control"
                                        onChange={this.handleChangeDrop}
                                        required
                                    >
                                        <option value="1">Open</option>
                                        <option value="2">In Progress</option>
                                        <option value="3">Closed</option>
                                        <option value="6">Resolved</option>
                                        <option value="7">Unresolved</option>
                                        <option value="8">Overdue</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div id="category-cont-select" className="col-4">
                            <div className="col-12 pl-2 pr-0">
                                <div className="form-group bd-t-0-force">
                                    <label className="form-control-label">
                                        Category:
                                    </label>
                                    <select
                                        id="category-select"
                                        name="cat"
                                        className="form-control"
                                        onChange={this.handleChangeDrop}
                                        value={cat}
                                        required
                                    >
                                        <option value="">
                                            -- Select Category --
                                        </option>
                                        {this.handleCategory()}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 pl-2 pr-0">
                                <div className="form-group bd-t-0-force">
                                    <label className="form-control-label">
                                        Sub Category:
                                    </label>
                                    <select
                                        id="subcategory-select"
                                        name="subcat"
                                        className="form-control"
                                        onChange={this.handleChangeDrop}
                                        value={subcat}
                                        required
                                    >
                                        <option value="">
                                            -- Select Category --
                                        </option>
                                        {this.handleSubCategory()}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 pl-2 pr-0">
                                <div className="form-group bd-t-0-force">
                                    <label className="form-control-label">
                                        Sub Sub Category:
                                    </label>
                                    <select
                                        id="subsubcategory-select"
                                        name="subsubcat"
                                        className="form-control"
                                        onChange={this.handleChangeDrop}
                                        value={subsubcat}
                                        required
                                    >
                                        <option value="">
                                            -- Select Category --
                                        </option>
                                        {this.handleSubSubCategory()}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="reply-cont mb-4">
                    <h4 className="mb-3">Reply Ticket</h4>
                    <div className="text-editor ht-200"></div>
                    <div className="button-cont mt-2 d-flex justify-content-end align-items-center">
                        {/* <p className="m-0 tx-danger">asdasda</p> */}
                        {/* <p className="m-0 mr-4 tx-success">asdadas</p> */}
                        <button
                            type="submit"
                            className="btn btn-primary mr-1"
                            disabled={false}
                        >
                            Save
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                        >
                            Close
                        </button>
                    </div>
                </div>

                <div className="timeline-cont">
                    <div className="title mb-3 d-flex align-items-center">
                        <h4 className="m-0">Conversation</h4>
                        <button
                            type="button"
                            onClick={() =>
                                get_ticket_detail(ticket_detail.ticket_number)
                            }
                            className="btn btn-outline-secondary rounded-circle wd-30 ht-30 d-inline-flex align-items-center justify-content-center ml-2 p-0"
                            disabled={ticket_detail ? "" : true}
                        >
                            <i className="fa fa-refresh"></i>
                        </button>
                    </div>

                    <Timeline
                        language={ticket_detail?.lang}
                        conversation={conversation}
                    />
                </div>
            </PartialModal>
        );
    }
}

export default DetailModal;
