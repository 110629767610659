import React, { Component } from "react";
import { date_parser } from "helper";
import DOMPurify from "dompurify";

class Timeline extends Component {
    sent_mapper = (is_sent) => {
        var classname, text;
        switch (is_sent) {
            case 1:
                classname = "bd-success tx-success";
                text = "SENT";
                break;
            default:
                classname = "bd-warning tx-warning";
                text = "PENDING";
                break;
        }
        return {
            class: classname,
            text: text
        };
    };

    render_choices = (data) => {
        return data.choices.map(
            (dt) =>
                `
                <input class="ml-2" type="radio" id="choice_${dt.id}" ${
                    data.answer === dt.id ? "checked" : ""
                } disabled /> 
                <label for="choice_${dt.id}">${dt.option}</label>
                `
        );
    };

    format_content = (data) => {
        if (typeof data === "object") {
            return `
                <h6 class='mb-1'>${data.title}</h6>
                <p class='mb-1'>${data.desc}</p>
                ${this.render_choices(data)}
                <p>${data.footer}</p>
            `;
        }

        // Replace CDN Link
        return data.replaceAll(
            "https://cdn.help.garena.co.id/",
            "https://proxy.cdn.garena.co.id/"
        );
    };

    loop_timeline = () => {
        const { conversation, language } = this.props;

        if (!conversation) {
            return;
        }

        return conversation.map((dt, idx) => {
            return (
                <div
                    key={idx}
                    className={
                        idx === 0
                            ? "timeline-item timeline-day"
                            : "timeline-item"
                    }
                >
                    <div className="timeline-time">
                        <div>{date_parser(dt.created_at)}</div>
                    </div>
                    <div className="timeline-body pd-r-25">
                        <p className="timeline-title d-flex align-items-center">
                            {dt.agent_email ? (
                                <>
                                    <span className="badge badge-pill badge-info">
                                        Agent
                                    </span>
                                    <span className="ml-1">
                                        {dt.agent_email.name}
                                    </span>
                                </>
                            ) : (
                                <>
                                    <span className="badge badge-pill badge-warning">
                                        User
                                    </span>
                                    <span className="ml-1">
                                        {dt.user_open?.nickname}
                                    </span>
                                </>
                            )}
                        </p>
                        <div
                            className="timeline-text text-justify"
                            style={{
                                direction: language === "ar" ? "rtl" : "ltr"
                            }}
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                    this.format_content(dt.content)
                                )
                            }}
                        />
                    </div>
                </div>
            );
        });
    };

    render() {
        return (
            <div id="Timeline" className="timeline-group tx-center">
                {this.loop_timeline()}
            </div>
        );
    }
}

export default Timeline;
