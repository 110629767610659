import React, { Component } from 'react';
import moment from 'moment';
import { join_room } from 'socket';

class Card extends Component {

    color_mapper = () => {
        const { status } = this.props;
        switch (status) {
            case "Open":
                return 'bg-success';
            case "In Progress":
                return 'bg-warning';
            default:
                return 'bg-danger';
        }
    }

    reply_mapper = () => {
        const { status, last_reply_by } = this.props;

        if (status === "Closed") {
            return {
                text: "Closed",
                color: "bg-dark"
            }
        }

        switch (last_reply_by) {
            case "User":
                return {
                    text: "New",
                    color: "bg-info"
                }
            default:
                return {
                    text: "Pending",
                    color: "bg-secondary"
                }
        }
    }

    renderAdditionalButton = () => {
        const { user, status, move_ticket, ticket_number } = this.props;
        if (user) {
            if (user?.position?.id !== 1) {
                return
            }

            if (status === "Trash") {
                return (
                    <div className="dropdown position-absolute" style={{ right: 0, top: 0, zIndex: 99 }}>
                        <div className="btn bg-gray-200 py-0 px-2" data-toggle="dropdown" >
                            <i className="icon ion-android-more-horizontal"></i>
                        </div>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                            <a
                                className="dropdown-item"
                                href=" "
                                data-toggle="modal"
                                data-target="#modal-delete-ticket"
                                onClick={() => move_ticket(ticket_number, 1)}
                            >
                                Restore
                            </a>
                            <a
                                className="dropdown-item"
                                href=" "
                                data-toggle="modal"
                                data-target="#modal-delete-ticket"
                                onClick={() => move_ticket(ticket_number, 5)}
                            >
                                Permanent Delete
                            </a>
                        </div>
                    </div>
                )
            }
            else {
                return (
                    <div className="dropdown position-absolute" style={{ right: 0, top: 0, zIndex: 99 }}>
                        <div className="btn bg-gray-200 py-0 px-2" data-toggle="dropdown" >
                            <i className="icon ion-android-more-horizontal"></i>
                        </div>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                            <a
                                className="dropdown-item"
                                href=" "
                                data-toggle="modal"
                                data-target="#modal-delete-ticket"
                                onClick={() => move_ticket(ticket_number, 4)}
                            >
                                Delete
                            </a>
                        </div>
                    </div>
                )
            }
        }
    }

    handleGetData = () => {
        const { ticket_number, get_data } = this.props;
        join_room(ticket_number);
        get_data();
    }

    render() {
        const {
            ticket_number,
            ticket_title,
            created_at,
            updated_at,
            closed_by,
            lang,
            age,
            category,
            user_open,
            snippet,
            status
        } = this.props;

        const mapper = this.reply_mapper();
        return (
            <div className="row ticket-cont pt-2 mb-3 mx-0 bd align-items-center">
                {this.renderAdditionalButton()}
                <div className="col-2 px-0 number_stats">
                    <p className="tx-bold tx-14">#{ticket_number}</p>
                    <div className="status-cont d-flex p-0">
                        <p className={"mt-1 p-0 tx-center bd-r tx-15 wd-100p tx-white " + this.color_mapper()}>{status.toUpperCase()}</p>
                    </div>
                    <div className="status-cont d-flex p-0">
                        <p className={"mt-0 p-0 tx-center bd-r tx-15 wd-100p tx-white " + mapper.color}>{mapper.text.toUpperCase()}</p>
                    </div>
                </div>
                <div className="col-7 problem-cont tx-left">
                    <div className="title tx-semibold tx-15"> {ticket_title} </div>
                    <div className="snippet tx-xthin tx-13" > {snippet} </div>
                </div>
                <div className="col-3 pr-0 last-updated tx-left">
                    <p className="tx-12">Date Created</p>
                    <p className="tx-13">{moment(created_at).format('DD MMM YYYY HH:mm:ss')}</p>
                </div>
                <div className="col-2 detail bg-gray-200 py-2 mt-2 bd-t bd-r tx-left">
                    <p className="label tx-xthin tx-12">Category</p>
                    <p className="content tx-13">{category}</p>
                </div>
                <div className="col detail bg-gray-200 py-2 mt-2 bd-t bd-r tx-left">
                    <p className="label tx-xthin tx-12">Raised by</p>
                    <p className="content tx-13">{user_open.open_id}</p>
                </div>
                <div className="col detail bg-gray-200 py-2 mt-2 bd-t bd-r tx-left">
                    <p className="label tx-xthin tx-12">Closed by</p>
                    <p className="content tx-13">{closed_by ? closed_by : "-"}</p>
                </div>
                <div className="col-2 detail bg-gray-200 py-2 mt-2 bd-t bd-r tx-left">
                    <p className="label tx-xthin tx-12">Last Updated</p>
                    <p className="content tx-13">{moment(updated_at).format('DD MMM YYYY HH:mm:ss')}</p>
                </div>
                <div className="col-1 detail bg-gray-200 py-2 mt-2 bd-t bd-r tx-left">
                    <p className="label tx-xthin tx-12">Language</p>
                    <p className="content tx-13">{lang ? lang.toUpperCase() : "-"}</p>
                </div>
                <div className="col-1 detail bg-gray-200 py-2 mt-2 bd-t tx-left">
                    <p className="label tx-xthin tx-12">Age</p>
                    <p className="content tx-13">{age} Days</p>
                </div>
                <div className="col-1 p-0 mt-2 bd-t d-inline-flex align-items-center justify-content-center align-self-stretch view-detail">
                    <span className="btn btn-teal wd-100p ht-100p tx-white d-inline-flex align-items-center justify-content-center bd-0 tx-18" data-toggle="modal" data-target="#modal-ticket" onClick={this.handleGetData}>
                        <i className="icon ion-eye"></i>
                    </span>
                </div>
            </div>
        );
    }
}

export default Card;