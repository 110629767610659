import React, { Component } from 'react';

import Search from './Search';

class Header extends Component {

    handleLogout = () => {
        window.localStorage.clear();
    }

    render() {
        const { data, get_ticket, change_nav } = this.props;

        return (
            <div className="slim-header">
                <div className="container">
                    <div className="slim-header-left">
                        <h2 className="slim-logo">
                            <a href={`${window.location.origin}/dashboard`}>
                                Garena <span>OTRS</span>
                            </a>
                        </h2>
                        <Search get_ticket={get_ticket} change_nav={change_nav} />
                    </div>
                    <div className="slim-header-right">
                        <div className="dropdown dropdown-c">
                            <a href="dropdown" className="logged-user" data-toggle="dropdown">
                                <img src={data && data.picture} alt="" />
                                <span>{data && data.name}</span>
                                <i className="fa fa-angle-down"></i>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right">
                                <nav className="nav">
                                    {/* <Link to='/profile' className="nav-link">
                                        <i className="icon ion-eye"></i> View Profile
                                    </Link> */}
                                    <a href="/" className="nav-link" onClick={this.handleLogout}>
                                        <i className="icon ion-forward"></i> Sign Out
                                    </a>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Header;